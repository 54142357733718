import { NextSeo } from "next-seo";
import React from "react";
import { SEOCommonsData, twitter } from "./constant";

const Meta = ({
  title,
  description,
  keywords,
  url,
  imageUrl,
  imageType,
  imageHeight,
  imageWidth,
  imageAlt,
  videoUrl,
  languageAlternates,
}) => {
  var aditional = [];
  if (keywords) {
    aditional.push({
      property: "keywords",
      content: keywords,
    });
  }
  aditional.push({
    property: "twitter:url",
    content: url,
  });

  return (
    <NextSeo
      title={title}
      description={description}
      canonical={url}
      languageAlternates={languageAlternates}
      additionalMetaTags={aditional}
      twitter={twitter}
      openGraph={{
        title,
        description,
        type: SEOCommonsData.ogType,
        images: imageUrl && [
          {
            url: imageUrl,
            alt: imageAlt,
            type: imageType,
            height: imageHeight,
            width: imageWidth,
          },
        ],
        videos: [
          {
            url: videoUrl,
            alt: SEOCommonsData.siteName,
          },
        ],
        locale: SEOCommonsData.locale,
        siteName: SEOCommonsData.siteName,
        url,
      }}
    />
  );
};

export default Meta;
